import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import {
  CartCounter,
  CartFooter,
  CartLink,
  CheckoutButtonMobile,
  CheckoutTouch,
  CartFooterPlaceholder,
} from '../Navigation/styles'
import { BrowserView, MobileView, isMobileSafari } from 'react-device-detect'
import '../../effects/Effects.css'
// import './effect.css'
import Newsletter from '../Newsletter'
import { changeName } from '../Navigation/changeNameMenu'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '17px',
    height: '17px',
    right: '12px',
    top: '10px',
  },
  bmBurgerBars: {
    background: 'black',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: 'white',
    padding: '5px 12px 0 0',
    width: '17px',
    height: '17px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: 'white',
    padding: '12px 0 12px 12px',
    fontSize: '1.15em',
    height: '100vh',
  },
  bmMorphShape: {
    fill: '#f2f2f2',
  },
  bmItemList: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'none',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: '#f2f2f2',
  },
}

const CartFooterMobile = props => {
  const {
    store: { checkout, subscription, adding },
  } = useContext(StoreContext)

  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  )

  const [searchOn, setSearchOn] = useState(false)
  const [homeSet, setHome] = useState(false)
  const [cartPage, setCart] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const [forceCSS, setForceCss] = useState(false)

  const [menCategories, setMenCategories] = useState([])
  const [menMenu, setMenMenu] = useState([])

  const [css, setCss] = useState('slide-in-top')

  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              title
              handle
              productType
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [allShopifyProduct])

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout.lineItems])

  useEffect(() => {
    setForceCss(true)
  }, [quantity, adding])

  useEffect(() => {
    const pathArray = window.location.pathname.split('/')
    if (pathArray[1] === 'cart') {
      setCart(true)
    } else {
      setCart(false)
    }

    setCurrentPath(pathArray[2])
  }, [])

  return (
    <div>
      <MobileView>
        {quantity > 0 ? (
          cartPage ? null : (
            <CartFooter to="/cart">
              <CheckoutTouch to="/cart"></CheckoutTouch>
              <CheckoutButtonMobile>Checkout</CheckoutButtonMobile>

              <CartLink to="/cart">
                Cart
                <CartCounter> [{quantity}]</CartCounter>
              </CartLink>

              {/* {cartPage ? (
                <CheckoutButtonMobile> Close</>
                <TouchPointMenu onClick={() => closePage()} />
              </div>
            ) : null} */}
            </CartFooter>
          )
        ) : null}
      </MobileView>
    </div>
  )
}

export default CartFooterMobile
