import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import Navigation from '../components/Navigation/index'
import CartFooterMobile from '../components/Cart/cartFooterMobile'
import ContextProvider from '~/provider/ContextProvider'
import { GlobalStyle } from '~/utils/styles'
import CookieConsent from 'react-cookie-consent'
import StoreContext from '~/context/StoreContext'
import {
  CartCounter,
  CartFooter,
  CartLink,
  CheckoutButtonMobile,
} from '../components/Navigation/styles'
import { MobileView } from 'react-device-detect'

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0px;
  max-width: 100%;
`

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const Layout = ({ children }) => {
  const [hideNavigation, setHideNavigation] = useState()
  const [consent, setConsent] = useState(false)

  useEffect(() => {
    const pathArray = window.location.pathname.split('/')
    if (pathArray[1] === 'cart') {
      setHideNavigation(true)
    } else {
      setHideNavigation(false)
    }
  }, [])

  useEffect(() => {
    if (window.localStorage.getItem('consent') === true) {
      setConsent(true)
    }
  }, [])

  const acceptCookies = () => {
    window.localStorage.setItem('consent', true)
    setConsent(true)
  }

  return (
    <ContextProvider>
      <GlobalStyle />
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <MobileView>
              <Navigation siteTitle={data.site.siteMetadata.title} />{' '}
            </MobileView>
            <Wrapper>{children}</Wrapper>
            <footer>
              <CartFooterMobile />
            </footer>
            {consent === true ? null : (
              <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="Cookies"
                style={{
                  background: '#f2f2f2',
                  color: 'black',
                  padding: '0px',
                }}
                buttonStyle={{
                  color: 'black',
                  fontSize: '13px',
                  background: 'transparent',
                  border: '1px solid black',
                }}
                expires={300}
                acceptOnScroll={true}
                onAccept={({ acceptedByScrolling }) => {
                  if (acceptedByScrolling) {
                    acceptCookies()
                  } else {
                  }
                }}
              >
                This website uses cookies to enhance the user experience.{' '}
                <Link
                  to="/costumer-care"
                  style={{ fontSize: '12px', color: 'black' }}
                >
                  {' '}
                  More
                </Link>
              </CookieConsent>
            )}
          </>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
