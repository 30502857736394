import React from 'react'

export const changeName = value => {
  switch (value) {
    case 'women_jackets_coats':
      return 'Jackets / Coats'
      break
    case 'women_outerwear':
      return 'Outerwear'
      break
    case 'women_pants':
      return 'Pants'
      break
    case 'women_denim':
      return 'Denim'
      break
    case 'women_tops':
      return 'Tops'
      break
    case 'women_tshirts':
      return 'T-shirts'
      break
    case 'women_knitwear':
      return 'Knitwear'
      break
    case 'women_hoodie':
      return 'Hoodies'
      break
    case 'women_crewneck':
      return 'Crewneck'
      break
    case 'women_facemask':
      return 'Face Mask'
      break
    case 'women_dresses':
      return 'Dresses'
      break
    case 'women_tshirts':
      return 'T-shirts'
      break
    case 'women_shoes':
      return 'Shoes'
      break
    case 'women_accessories':
      return 'Accessories'
      break
    case 'men_jackets':
      return 'Jackets'
      break
    case 'men_pants':
      return 'Pants'
      break
    case 'men_tshirts':
      return 'T-shirts'
      break
    case 'men_shoes':
      return 'Shoes'
      break
    case 'men_accessories':
      return 'Accessories'
      break
    case 'men_face_mask':
      return 'Face Mask'
      break
    default:
  }
}
