import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import {
  Wrapper,
  CartCounter,
  MenuLink,
  TextMenu,
  TextMenuMobile,
  Space,
  Space3,
  MenuBar,
  MenuBarHome,
  MenuScrollDown,
  MenuContainer,
  MenuLogoLink,
  CircleMenu,
  Proucttypes,
  CloseButton,
  CloseButtonPP,
  CloseButtonCheckout,
  CloseButtonCheckoutPP,
  SubMenuWrapper,
  SeasonWrapper,
  Year,
  CartFooter,
  CartLink,
  CheckoutButton,
  CheckoutButtonMobile,
  TextMenuBlack,
  SearchInput,
  MenuQuestionItem,
  HeadlinesMenuMobile,
  TouchPointMenu,
  CheckoutTouch,
} from './styles'
import { BrowserView, MobileView, isMobileSafari } from 'react-device-detect'
import Circle from '../../images/circle.png'
import CircleWhite from '../../images/circle_white.png'
import Close from '../../images/close.png'
import '../../effects/Effects.css'
// import './effect.css'
import Newsletter from '../Newsletter'
import { changeName } from './changeNameMenu'
import Div100vh from 'react-div-100vh'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '17px',
    height: '17px',
    right: '12px',
    top: '10px',
  },
  bmBurgerBars: {
    background: 'black',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: 'white',
    padding: '5px 12px 0 0',
    width: '17px',
    height: '17px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: 'white',
    padding: '12px 0 12px 12px',
    fontSize: '1.15em',
    height: '100vh',
  },
  bmMorphShape: {
    fill: '#f2f2f2',
  },
  bmItemList: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'none',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: '#f2f2f2',
  },
}

const Navigation = props => {
  const {
    setShowTypes,
    setSubscription,
    store: { checkout, subscription, adding },
  } = useContext(StoreContext)

  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  )

  const [menuOpen, setMenuOpen] = useState(false)
  const [openW, setOpenW] = useState(false)
  const [openM, setOpenM] = useState(false)
  const [searchOn, setSearchOn] = useState(false)
  const [homeSet, setHome] = useState(false)
  const [cartPage, setCart] = useState(false)
  const [womenCategories, setWomenCategories] = useState([])
  const [womenMenu, setWomenMenu] = useState([])
  const [currentPath, setCurrentPath] = useState('')
  const [forceCSS, setForceCss] = useState(false)
  const [productPage, setProductPage] = useState(false)

  const [menCategories, setMenCategories] = useState([])
  const [menMenu, setMenMenu] = useState([])

  const [css, setCss] = useState('slide-in-top')
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              title
              handle
              productType
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    allShopifyProduct.edges.map(({ node: { productType, handle } }) => {
      if (productType.substring(0, 3) === 'wom') {
        womenCategories.push(productType)
        setWomenCategories()
      } else if (productType.substring(0, 3) === 'men') {
        const BeforeSorting = []
        BeforeSorting.push(productType)
        BeforeSorting.sort(function(a, b) {
          return a - b
        })
        setMenCategories(BeforeSorting)
      }
    })
    var womenUnique = womenCategories.filter(function(elem, index, self) {
      return index === self.indexOf(elem)
    })
    setWomenMenu(womenUnique)

    var menUnique = menCategories.filter(function(elem, index, self) {
      return index === self.indexOf(elem)
    })

    setMenCategories(menUnique)
  }, [allShopifyProduct])

  const closePage = () => {
    setCss('slide-out-top')
    setTimeout(() => {
      setMenuOpen(!menuOpen)
    }, 1000)
  }

  const openMenu = () => {
    setCss('slide-in-top')
    setTimeout(() => setMenuOpen(true), 200)
  }

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [allShopifyProduct, adding])

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout.lineItems])

  useEffect(() => {
    setForceCss(true)
  }, [quantity, adding])

  const openSearchCloseMenu = value => {
    if (value == 'woman') {
      setOpenW(!openW)
      setOpenM(false)
      setSearchOn(false)
    } else {
      setOpenM(!openM)
      setSearchOn(false)
      setOpenW(false)
    }
  }

  useEffect(() => {
    const pathArray = window.location.pathname.split('/')
    if (pathArray[1] === 'search') {
      setSearchOn(true)
    } else {
      setSearchOn(false)
    }

    if (pathArray[1] === '') {
      setHome(true)
    } else {
      setHome(false)
    }

    if (pathArray[1] === 'cart') {
      setCart(true)
    } else {
      setCart(false)
    }

    if (pathArray[1] === 'product') {
      setProductPage(true)
    } else {
      setProductPage(false)
    }
    setCurrentPath(pathArray[2])
  }, [menuOpen])

  const checkWhatCloseButton = () => {
    switch (productPage) {
      case true:
        return quantity > 0 ? (
          isMobileSafari ? (
            <isMobileSafari>
              <CloseButtonCheckout
                style={{ position: 'fixed', bottom: '15%' }}
                className={css}
                onClick={() => closePage()}
              >
                Close
              </CloseButtonCheckout>
            </isMobileSafari>
          ) : (
            <CloseButtonCheckout className={css} onClick={() => closePage()}>
              Close
            </CloseButtonCheckout>
          )
        ) : isMobileSafari ? (
          <CloseButtonPP
            style={{ position: 'fixed', bottom: '15%' }}
            className={css}
            onClick={() => closePage()}
          >
            Close
          </CloseButtonPP>
        ) : (
          <CloseButton className={css} onClick={() => closePage()}>
            Close
          </CloseButton>
        )
        break
      case false:
        return quantity > 0 ? (
          <CloseButtonCheckout className={css} onClick={() => closePage()}>
            Close
          </CloseButtonCheckout>
        ) : (
          <CloseButton className={css} onClick={() => closePage()}>
            Close
          </CloseButton>
        )
        break
      default:
        return quantity > 0 ? (
          <CloseButtonCheckout className={css} onClick={() => closePage()}>
            Close
          </CloseButtonCheckout>
        ) : (
          <CloseButton className={css} onClick={() => closePage()}>
            Close
          </CloseButton>
        )
    }
  }

  return (
    <div>
      <BrowserView>
        <div>
          <Wrapper>
            <MenuLink to="/">
              <TextMenu style={{ color: props.color }}>Bureau de Stil</TextMenu>
            </MenuLink>
            {searchOn ? (
              <SearchInput
                type="text"
                placeholder="Enter Search"
                onChange={event => props.getSearchValue(event.target.value)}
              />
            ) : (
              <MenuLink onClick={() => setSearchOn(true)} to="/search">
                <TextMenu style={{ color: props.color }}>Search</TextMenu>
              </MenuLink>
            )}

            <Space />
            {homeSet ? (
              <SeasonWrapper onClick={() => setSearchOn(false)}>
                <Link
                  onClick={() => closePage()}
                  style={{ borderBottom: 'none', textDecoration: 'none' }}
                  to={`/products/${allShopifyProduct.edges[0].node.productType}/`}
                >
                  <TextMenu style={{ color: props.color }}>Shop </TextMenu>
                </Link>
                {/* <Year style={{ color: props.color }}></Year> */}
              </SeasonWrapper>
            ) : null}

            {homeSet ? null : womenMenu.length !== 0 ? (
              <TextMenu
                style={{ color: props.color }}
                onClick={() => openSearchCloseMenu('woman')}
              >
                Shop
              </TextMenu>
            ) : null}

            {homeSet ? null : (
              <SubMenuWrapper onClick={() => setSearchOn(false)}>
                {womenMenu.map(item => (
                  <p>
                    <MenuLink
                      style={{ color: props.color }}
                      to={`/products/${item}/`}
                    >
                      <TextMenu
                        style={{
                          color:
                            currentPath === item ? 'darkgrey' : props.color,
                        }}
                        onClick={() => setSearchOn(false)}
                      >
                        {changeName(item)}
                      </TextMenu>
                    </MenuLink>
                  </p>
                ))}
              </SubMenuWrapper>
            )}

            {homeSet ? null : menMenu.length !== 0 ? (
              <TextMenu
                style={{ color: props.color }}
                onClick={() => openSearchCloseMenu('men')}
              >
                Shop
              </TextMenu>
            ) : null}

            {openM ? (
              <SubMenuWrapper
                style={{ color: props.color }}
                onClick={() => setSearchOn(false)}
              >
                {menMenu.map(item => (
                  <p>
                    <MenuLink
                      style={{ color: props.color }}
                      to={`/products/${item}/`}
                    >
                      <TextMenu
                        style={{
                          color:
                            currentPath === item ? 'darkgrey' : props.color,
                        }}
                        onClick={() => setSearchOn(false)}
                      >
                        {changeName(item)}
                      </TextMenu>
                    </MenuLink>
                  </p>
                ))}
              </SubMenuWrapper>
            ) : null}

            <Space />

            <Link style={{ textDecoration: 'none' }} to="/costumer-care">
              <TextMenuBlack
                style={{ color: props.color }}
                onClick={() => setSearchOn(false)}
              >
                Customer Care
              </TextMenuBlack>
            </Link>

            {/* <MenuLink to="/press">
              <TextMenuBlack
                style={{ color: props.color }}
                onClick={() => setSearchOn(false)}
              >
                Press
              </TextMenuBlack>
            </MenuLink> */}

            <a
              style={{ textDecoration: 'none' }}
              href="https://www.instagram.com/bureaudestil/?hl=en"
              target="_blank"
            >
              <TextMenuBlack
                style={{ color: props.color }}
                onClick={() => setSearchOn(false)}
              >
                Instagram
              </TextMenuBlack>
            </a>

            <TextMenuBlack
              style={{ color: props.color }}
              onClick={() => setSubscription(true)}
            >
              Subscribe
            </TextMenuBlack>
          </Wrapper>

          {subscription ? <Newsletter /> : null}

          {quantity > 0 ? (
            <CartFooter to="/cart">
              <CartLink to="/cart">
                Cart
                <CartCounter> [{quantity}]</CartCounter>
              </CartLink>

              <CheckoutButton style={{ textDecoration: 'none' }} to="/cart">
                Checkout
              </CheckoutButton>
            </CartFooter>
          ) : null}
        </div>
      </BrowserView>

      <MobileView>
        {homeSet && !cartPage ? (
          <MenuBarHome onClick={() => openMenu()}>
            <MenuLogoLink style={{ textDecoration: 'none' }} to="/">
              {menuOpen || props.homeMenuOpen ? (
                searchOn ? null : (
                  <MenuLink onClick={() => closePage()} to="/search">
                    <TextMenu className={css} style={{ color: props.color }}>
                      Search
                    </TextMenu>
                  </MenuLink>
                )
              ) : null}
            </MenuLogoLink>

            <CircleMenu onClick={() => openMenu()}>
              {menuOpen ? (
                <img
                  onClick={() => closePage()}
                  style={{ width: '18px' }}
                  src={Close}
                />
              ) : (
                <img
                  onClick={() => openMenu()}
                  style={{ width: '18px' }}
                  src={CircleWhite}
                />
              )}
            </CircleMenu>

            {menuOpen ? (
              <TouchPointMenu onClick={() => closePage()} />
            ) : (
              <TouchPointMenu onClick={() => openMenu()} />
            )}
          </MenuBarHome>
        ) : (
          // Menu Open Bar
          <MenuBar onClick={() => openMenu()}>
            {menuOpen || props.homeMenuOpen ? (
              searchOn ? null : (
                <MenuLink
                  to="/search"
                  onClick={() => closePage()}
                  style={{
                    background: 'white',
                    width: '50%',
                    position: 'fixed',
                    overflow: 'hidden',
                    left: '12px',
                  }}
                >
                  <TextMenu className={css} style={{ color: props.color }}>
                    Search
                  </TextMenu>
                </MenuLink>
              )
            ) : (
              <MenuLink to="/">
                <TextMenu>Bureau de Stil</TextMenu>
              </MenuLink>
            )}

            <CircleMenu>
              {menuOpen ? (
                <img
                  onClick={() => closePage()}
                  style={{ width: '18px' }}
                  src={Close}
                />
              ) : (
                <img
                  onClick={() => openMenu()}
                  style={{ width: '18px' }}
                  src={menuOpen ? '' : Circle}
                />
              )}
            </CircleMenu>
            {menuOpen ? (
              <TouchPointMenu onClick={() => closePage()} />
            ) : (
              <TouchPointMenu onClick={() => openMenu()} />
            )}
          </MenuBar>
        )}

        {menuOpen || props.homeMenuOpen ? (
          <div>
            <MenuScrollDown className={css}>
              <Div100vh>
                <Space3 />
                <Space3 />
                {/* <TextMenuMobile>Shop </TextMenuMobile> */}
                {womenMenu.length !== 0 ? (
                  <HeadlinesMenuMobile onClick={() => setOpenW(!openW)}>
                    Shop
                  </HeadlinesMenuMobile>
                ) : null}
                <Proucttypes
                  // className="Menufade"
                  onClick={() => setMenuOpen(false)}
                  style={{ textDecoration: 'none' }}
                >
                  {openW
                    ? womenMenu.map(item => (
                        <p>
                          <Link
                            onClick={() => closePage()}
                            style={{
                              borderBottom: 'none',
                              textDecoration: 'none',
                            }}
                            to={`/products/${item}/`}
                          >
                            <TextMenuMobile
                              style={{ color: props.color }}
                              onClick={() => setSearchOn(false)}
                            >
                              {changeName(item)}
                            </TextMenuMobile>
                          </Link>
                        </p>
                      ))
                    : null}
                </Proucttypes>

                {menMenu.length !== 0 ? (
                  <HeadlinesMenuMobile onClick={() => setOpenM(!openM)}>
                    Shop
                  </HeadlinesMenuMobile>
                ) : null}
                {openM ? (
                  <Proucttypes
                    // className="Menufade"
                    onClick={() => setMenuOpen(false)}
                  >
                    {menMenu.map(item => (
                      <p>
                        <Link
                          onClick={() => closePage()}
                          style={{
                            borderBottom: 'none',
                            textDecoration: 'none',
                          }}
                          to={`/products/${item}/`}
                        >
                          <TextMenuMobile
                            style={{ color: props.color }}
                            onClick={() => setSearchOn(false)}
                          >
                            {changeName(item)}
                          </TextMenuMobile>
                        </Link>
                      </p>
                    ))}
                  </Proucttypes>
                ) : null}
                <Space3 />
                <Link
                  onClick={() => closePage()}
                  style={{
                    borderBottom: 'none',
                    textDecoration: 'none',
                    lineHeight: '35px',
                  }}
                  to={`/costumer-care/`}
                >
                  <MenuQuestionItem>
                    <p style={{ lineHeight: '38px' }}>Customer Service</p>
                  </MenuQuestionItem>
                </Link>
                <Link
                  onClick={() => closePage()}
                  style={{ borderBottom: 'none', textDecoration: 'none' }}
                  to={`/lookbook/`}
                >
                  <MenuQuestionItem onClick={() => closePage()}>
                    Lookbook
                  </MenuQuestionItem>
                </Link>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://www.instagram.com/bureaudestil/?hl=en"
                  target="_blank"
                >
                  <MenuQuestionItem onClick={() => closePage()}>
                    <p style={{ lineHeight: '38px' }}>Instagram</p>
                  </MenuQuestionItem>
                </a>
                {/* <MenuQuestionItem onClick={() => closePage()}>
                <p>Terms & Conditions </p>
              </MenuQuestionItem> */}

                <Link
                  onClick={() => closePage()}
                  style={{ borderBottom: 'none', textDecoration: 'none' }}
                  to={`/subscribe/`}
                >
                  <MenuQuestionItem onClick={() => closePage()}>
                    Subscribe
                  </MenuQuestionItem>
                </Link>
              </Div100vh>
            </MenuScrollDown>

            {/* close button when not on product page */}
            {quantity > 0 ? (
              <CloseButtonCheckout className={css} onClick={() => closePage()}>
                Close
              </CloseButtonCheckout>
            ) : (
              <CloseButton className={css} onClick={() => closePage()}>
                Close
              </CloseButton>
            )}
            {/* <footer>{checkWhatCloseButton()}</footer> */}
          </div>
        ) : null}
      </MobileView>
    </div>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation

// {searchOn ? (
//   <SearchItem>
//     <form onSubmit={e => setSearchItem(e.target.value)}>
//       <input
//         style={{
//           position: 'relative',
//           top: '2px',
//           fontSize: '12px',
//           fontFamily: 'LTUnivers-BasicRegular',
//           background: '#f2f2f2',
//           textAlign: 'right',
//           border: 'none',
//         }}
//         placeholder="Enter Search"
//       />
//     </form>
//   </SearchItem>
// ) : (
//   <MobileSearchButton>
//     <p
//       onClick={() => props.setSearchOn(true)}
//       style={{
//         position: 'relative',
//         fontSize: '14px',
//         fontFamily: 'LTUnivers-BasicRegular',
//       }}
//     >
//       {' '}
//       Search
//     </p>
//   </MobileSearchButton>
// )}
