import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const Wrapper = styled.div`
  background: transparent;
  margin-bottom: 1.45rem;
  position: fixed;
  top: 0;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const SubMenuWrapper = styled.div`
  padding: 0 0 0 35px;
`
export const SeasonWrapper = styled.div`
  display: flex;
`
export const Year = styled.div`
  padding: 0 0 0 15px;
`
export const MenuLink = styled(Link)`
  color: black;
  text-decoration: none;
`

export const CartFooter = styled(Link)`
  background-color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 42px;
  z-index: 2000;
  text-decoration: none;
  transform: translateZ(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);
`

export const CartLink = styled(Link)`
  color: white;
  position: fixed;
  left: 16px;
  bottom: 0;
  text-decoration: none;
`

export const CheckoutButton = styled(Link)`
  color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
`

export const CheckoutButtonMobile = styled(Link)`
  color: white;
  display: flex;
  justify-content: center;
  -webkit-appearance: none;
`

export const MenuBar = styled.span`
  background: #ffffff;
  display: grid;
  width: 97%;
  height: 42px;
  position: fixed;
  top: 0;
  left: 0px;
  font-size: 12px;
  z-index: 1000;
  padding: 0 0 0 12px;
  text-decoration: none;
  transform: translateZ(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
`

export const MenuBarHome = styled.span`
  background: transparent;
  display: grid;
  width: 100%;
  height: 42px;
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 10;
  font-size: 12px;
`

export const CartCounter = styled.span`
  z-index: 20;
`

export const TextMenu = styled.div`
  color: black;
  text-decoration: none;
  border-bottom: none;
  height: 42px;
  font-size: 12px;
  font-family: 'LTUnivers-BasicRegular';
  cursor: pointer;
  outline: none;
  width: max-content;
  &:hover {
    color: #b3b3b3;
  }
`

export const TextMenuBlack = styled.div`
  color: #b3b3b3;
  text-decoration: none;
  border-bottom: none;
  height: 42px;
  font-size: 12px;
  font-family: 'LTUnivers-BasicRegular';
  cursor: pointer;
  outline: none;
  &:hover {
    color: #1a1a1a;
  }
`

export const HeadlinesMenuMobile = styled.div`
  color: black;
  text-decoration: none;
  border-bottom: none;
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  outline: none;
`

export const TextMenuMobile = styled.div`
  color: black;
  text-decoration: none;
  border-bottom: none;
  font-size: 12px;
  line-height: 42px;
  font-family: 'LTUnivers-BasicRegular';
  outline: none;
  &:hover {
    color: #b3b3b3;
  }
`

export const TitleMenu = styled.div`
  color: black;
  text-decoration: none;
  border-bottom: none;
  font-size: 12px;
  line-height: 1.5rem;
  font-family: 'LTUnivers-BasicRegular';
  margin-top: 4rem;
  outline: none;
  border: none;
  :active: {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
  :focus: {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
`

export const PressContact = styled.div`
  color: black;
  text-decoration: none;
  border-bottom: none;
  font-size: 12px;
  line-height: 1.5rem;
  font-family: 'LTUnivers-BasicRegular';
`

export const TextSearch = styled.div`
  color: black;
  text-decoration: none;
  font-size: 12px;
  line-height: 10;
  font-family: 'LTUnivers-BasicRegular';
`

export const Space = styled.div`
  height: 42px;
`

export const Space3 = styled.div`
  height: 42px;
`

export const MenuScrollDown = styled.div`
  background-color: white;
  width: 100%;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
`

export const MenuContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const CircleMenu = styled.div`
  color: black;
  text-decoration: none;
  font-size: 12px;
  margin: 0 0 0 12px;
  line-height: 1.5rem;
  align-self: center;
  position: fixed;
  right: 12px;
  top: 11px;
`
export const MenuItem = styled(Link)`
  color: black;
  text-decoration: none;
  line-height: 42px;
  &:hover {
    color: #b3b3b3;
  }
  cursor: pointer;
`

export const MenuQuestionItem = styled(Link)`
  color: #b3b3b3;
  text-decoration: none;
  line-height: 42px;
  &:hover {
    color: black;
  }
  cursor: pointer;s
`

export const MenuLogoLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 12px;
  margin: 0 0 0 12px;
  line-height: 1.5rem;
  align-self: center;
  cursor: pointer;
`

export const Proucttypes = styled.div`
  color: black;
  text-decoration: none;
  font-size: 12px;
  margin: 0 0 0 33px;
  line-height: 42px;
  align-self: center;
`
export const GreyText = styled(Link)`
  color: darkgrey;
  text-decoration: none;
  font-size: 12px;
  margin: 0 0 0 12px;
  line-height: 1.5rem;
  align-self: center;
`
export const SearchItem = styled.div`
  position: fixed;
  left: 12px;
  top: 12px;
  z-index: 10;
  display: flex;
  font-size: 12px;
`

export const CloseButton = styled.div`
  background: darkgrey;
  color: white;
  width: 100%;
  height: 42px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  -webkit-appearance: none;
  text-decoration: none;
  transform: translateZ(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
`
export const CloseButtonCheckout = styled.div`
  background: darkgrey;
  color: white;
  width: 100%;
  height: 42px;
  position: fixed;
  bottom: 42px;
  left: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  -webkit-appearance: none;
  text-decoration: none;
  transform: translateZ(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
`

export const CloseButtonPP = styled.div`
  background: darkgrey;
  color: white;
  width: 100%;
  height: 42px;
  position: fixed;
  bottom: 40px;
  left: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  -webkit-appearance: none;
`
export const CloseButtonCheckoutPP = styled.div`
  background: darkgrey;
  color: white;
  width: 100%;
  height: 42px;
  position: fixed;
  bottom: 60px;
  left: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  -webkit-appearance: none;
`

export const FixedCostumerCare = styled.div`
  position: fixed;
  left: 16px;
`

export const TouchPointMenu = styled.div`
  position: fixed;
  top: 0;
  width: 70px;
  right: 0;
  height: 10vh;
  background: transparent;
`

export const CheckoutTouch = styled(Link)`
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  height: 8vh;
  visibility: none;
  background: transparent;
`
