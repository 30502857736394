import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import {
  CheckoutButton,
  SubscriptionWrapper,
  SubscriptionWrapperMobile,
  SubscribeInput,
  SubscribeInputMobile,
  NewsletterConditions,
  NewsletterConditionsMobile,
  SubscribeButton,
  SubscriptionLink,
} from './styles'
import { slide as Menu } from 'react-burger-menu'
import { BrowserView, MobileView } from 'react-device-detect'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import NotFoundPage from '../../pages/404'
import Close from '../../images/close.png'

const Newsletter = props => {
  const { setSubscription } = useContext(StoreContext)
  const [email, setEmail] = useState('')
  const [subscriptionMessage, setSubscriptionMessage] = useState(false)
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] = useState(
    false
  )

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === 'error') {
          setSubscriptionErrorMessage(true)
          setTimeout(() => {
            setSubscription(false)
          }, 4000)
        } else if (data.result === 'success') {
          setSubscriptionMessage(true)
          setTimeout(() => {
            setSubscription(false)
          }, 4000)
        }
      })
      .catch(() => {
        return <NotFoundPage />
      })
  }

  return (
    <div>
      <BrowserView>
        <SubscriptionWrapper>
          <NewsletterConditions>
            Sign up to receive information regarding exclusive collection
            previews, special events, and seasonal sale offers.{' '}
          </NewsletterConditions>
          <div>
            {/* <NewsletterConditions>Email </NewsletterConditions> */}
            <form>
              <SubscribeInput
                placeholder="Enter your Email"
                name="email"
                onChange={e => setEmail(e.target.value)}
              />
            </form>
          </div>
          <div>
            <SubscribeButton onClick={e => handleSubmit(e)}>
              Subscribe
            </SubscribeButton>
          </div>
          <img
            onClick={() => setSubscription(false)}
            src={Close}
            style={{
              width: '18px',
              position: 'absolute',
              top: '15px',
              right: '15px',
              cursor: 'pointer',
            }}
          />
        </SubscriptionWrapper>

        {subscriptionErrorMessage ? (
          <SubscriptionWrapper>
            Something went wrong.. you might be already subscribed
          </SubscriptionWrapper>
        ) : null}

        {subscriptionMessage ? (
          <SubscriptionWrapper>Thank you for subscribing!</SubscriptionWrapper>
        ) : null}
      </BrowserView>

      <MobileView>
        <SubscriptionWrapperMobile>
          <NewsletterConditionsMobile>
            Sign up to receive information regarding exclusive collection
            previews, special events, and seasonal sale offers.{' '}
          </NewsletterConditionsMobile>
          <div>
            {/* <NewsletterConditions>Email </NewsletterConditions> */}
            <form>
              <SubscribeInputMobile
                name="email"
                placeholder="Enter your Email"
                onChange={e => setEmail(e.target.value)}
              />
            </form>
          </div>
          <div>
            <SubscribeButton onClick={e => handleSubmit(e)}>
              Subscribe
            </SubscribeButton>
          </div>
          <img
            onClick={() => setSubscription(false)}
            src={Close}
            style={{
              width: '18px',
              position: 'absolute',
              top: '15px',
              right: '15px',
              cursor: 'pointer',
            }}
          />
        </SubscriptionWrapperMobile>
        {subscriptionErrorMessage ? (
          <div>
            <SubscriptionWrapperMobile>
              Something went wrong.. you might be already subscribed
            </SubscriptionWrapperMobile>
            <SubscriptionLink to="/">Continue</SubscriptionLink>
          </div>
        ) : null}

        {subscriptionMessage ? (
          <div>
            <SubscriptionWrapperMobile>
              Thank you for subscribing!
            </SubscriptionWrapperMobile>
            <SubscriptionLink to="/">Continue</SubscriptionLink>
          </div>
        ) : null}
      </MobileView>
    </div>
  )
}

export default Newsletter
