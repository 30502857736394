import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
// BDSTRegularTTF
import { LTUniversTTF, BDSTRegularTTF } from '../fonts/index'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
    @font-face {
      font-family: 'LTUnivers-BasicRegular';
      src: url('${LTUniversTTF}') format("woff2"); url(fonts/LTUnivers-BasicRegular.eot?#iefix') format('embedded-opentype'), 
      url(fonts/LTUnivers-BasicRegular.woff) format('woff'),
      url(fonts/LTUnivers-BasicRegular.ttf) format('truetype'),
      url(fonts/LTUnivers-BasicRegular.woff2) format("woff2");
      
    }
    @font-face {
      font-family: 'BDST';
      src:  url('${BDSTRegularTTF}') format("woff2"); url(fonts/BDST-Regular.eot?#iefix') format('embedded-opentype'),
          url(fonts/BDST-Regular.woff) format('woff'),
             url(fonts/BDST-Regular.ttf) format('truetype'),
             url(fonts/BDST-Regular.woff2) format("woff2");
             font-size: 70px;
             line-height: 5.0rem;    
             letter-spacing: 0.05em;     
             
    }
      body {
        margin: 0;
        font-family: 'LTUnivers-BasicRegular';
        line-height: 42px; 
        font-size: 14px;
        color: black;
        max-height: 100% !important;
        max-width: 100% !important;
        height  : 100%;
        overflow: auto;
        position: relative;
      }
      html {
        font-family: 'LTUnivers-BasicRegular';
        font-weight: 100;
        line-height: 42px; 
        font-size: 14px;
        color: black;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        background-color: white;
        max-height: 100% !important;
        max-width: 100% !important;
        height  : 100%;
        overflow: hidden;
        position: relative;
      }
      div {
        line-height: 42px; 
        font-size: 12px;
      }
      p {
        line-height: 21px; 
        font-size: 12px;
      }
    `}
  />
)

export const GeneralGrid = styled.div`
  display: grid;
  grid-template-columns: [sidebar] 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
  grid-column-gap: 16px;
  padding: 16px;
  overflow-x: hidden;
`

export const TermsGrid = styled.div`
  display: grid;
  padding: 16px;
  overflow-x: hidden;
`

export const TermsGridMobile = styled.div`
  display: grid;
  padding: 12px;
  overflow-x: hidden;
`

export const BureauDeStilHome = styled.div`
  grid-column-start: 6;
  grid-column-end: 7;
`

export const BureauDeStilHomeMobile = styled.div`
  grid-column-start: 0;
  grid-column-end: 1;
  font-family: 'BDST';
  font-size: 30px;
  line-height: 25px;
  color: white;
`

export const BureauDeStil = styled.div`
  font-family: 'BDST';
  font-size: 30px;
  line-height: 14px;
  color: white;
  padding: 16px 0 0 0;
`

export const SeasonHome = styled.div`
  position: absolute;
  bottom: 16px;
  z-index: -1;
`
export const ShopHome = styled.div`
  position: absolute;
  bottom: 50%;
  z-index: -1;
`

export const SeasonHomeMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  font-family: 'BDST';
  font-size: 30px;
  line-height: 25px;
  color: white;
`

export const CostumerMenu = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;
  position: fixed;
  left: 23%;
  top: 25px;
`
export const CostumerMenuItems = styled.div`
  font-family: 'BDST';
  font-size: 30px;
  line-height: 42px;
  cursor: pointer;
  overflow-y: auto;
  margin: -15px 0 0 0;
  cursor: pointer;
`

export const CostumerTextField = styled.div`
  grid-column-start: 5;
  grid-column-end: 8;
`

export const CostumerText = styled.div`
  font-family: 'LTUnivers-BasicRegular';
  line-height: 1.5em;
  font-size: 14px;
`

export const ContactField = styled.div`
  grid-column-start: 5;
  grid-column-end: 7;
  margin: -5px 0 0 0px;
`

export const ContactText = styled.div`
  font-family: 'BDST';
  font-size: 30px;
  line-height: 42px;
`

export const ContactLink = styled.a`
  font-family: 'LTUnivers-BasicRegular';
  line-height: 1.5em;
  font-size: 12px;
  color: black;
  text-decoration: none;
  underline: none;
  display: block;
`

export const GeneralGridMobile = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 12px;
  padding: 12px;
`

export const GeneralGridMobileProduct = styled.div`
  display: grid;
  grid-template-columns: 50% 46%;
  grid-column-gap: 12px;
  padding: 12px;
  overflow-x: hidden;
`

export const SearchBarMobile = styled.input`
  width: 100%;
  outline: 0;
  border: none;
  text-align: left;
  margin: 0 12px 0 12px;
  background: white;
  height: 42px;
  z-index: 200;
  @media screen and (max-width: 767px) {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
`
export const CloseIcons = styled(Image)`
  width: 18px;
  height: 18px;
  position: fixed;
  right: 12px;
  top: 12px;
`

export const ResultsTitleMobile = styled.div`
  margin: 42px 0 0px 0;
`

export const ClearTitleMobile = styled.div`
  text-align: right;
  margin: 42px 12px 0px 0;
  color: #cccccc;
`

// old

export const ImgGrid = styled(Image)`
  grid-column: 2 / span 3;
  margin-bottom: 1.45rem;
`

export const ImgGridMobile = styled(Image)`
  max-width: 100%;
  min-width: 140px;
  min-height: 350px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 50px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`

export const ImgCategory = styled(Image)`
  max-width: 250px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  display: grid;
  grid-template-columns: 50% 50%;
`

export const ImgProductPage = styled(Image)`
  min-width: 550px;
  grid-column-start: 2;
  grid-column-end: five;
`

export const ImgProductPageMobile = styled(Image)`
  min-width: 90%;
  margin-right: 12px;
  margin-top: 30px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`

export const TopMobilePart = styled.div`
  display: grid;
  padding-top: 60px;
  grid-template-columns: 50% 50%;
  font-family: 'LTUnivers-BasicRegular';
`

export const ImgPlaceholder = styled.div`
  min-width: 500px;
  height: 500px;
  margin-right: 0;
  margin-top: 30px;
`

export const ImgContainer = styled.div`
  max-width: 400px;
  grid-column-start: 2;
  grid-column-end: five;
  justify-self: center;
`
export const ImgContainerMobile = styled.div`
  display: flex;
  overflow-x: auto;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns:
    minmax(200px, 500px) minmax(200px, 500px) minmax(200px, 500px)
    minmax(200px, 500px) minmax(200px, 500px);
  grid-template-rows: 40% 60%;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 500px 500px;
  margin-left: 350px;
  font-family: 'LTUnivers-BasicRegular';
`
export const MobileWrapper = styled.div`
  margin: 0 12px 12px 12px;
  width: 100%;
`

export const PhotoLeft = styled.div``

export const PhotoRight = styled.div`
  flex-basis: 50%;
`

export const FirstGrid = styled.div`
  margin: 0;
`

export const SecondGrid = styled.div`
  margin: 0;
`

export const ThirdGrid = styled.div``

export const FourthGrid = styled.div``

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: 'left . right';
  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
  margin-top: 100px;
`

export const MainContent = styled.main`
  margin-top: 70px;
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const InfoLogo = styled.div``

export const MobileInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 70px 12px 12px 12px;
  background: #f2f2f2;
`

export const BureauDeStyle = styled.div`
  font-family: 'BDST';
  font-size: 70px;
  line-height: 5rem;
  color: white;
  color: black;
`

export const AboutText = styled.div`
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  color: white;
  background: #f2f2f2;
  padding: 12px;
  color: black;
  width: 90%;
`

export const TermsWrapper = styled.div`
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  color: black;
  padding: 12px;
  color: black;
  padding: 0 0 0 200px;
  line-height: 1.5em;
`

export const TermsHeadline = styled.p`
  font-family: 'BDST';
  font-size: 70px;
  line-height: 5rem;
  color: white;
  color: black;
`

export const TermsSubheadline = styled.p`
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  color: black;
  padding: 0 12px 0 12px;
  color: black;
  margin: 0 0 0 0;
`

export const TermsText = styled.p`
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  color: black;
  padding: 12px;
  color: black;
`

export const TermsWrapperMobile = styled.div`
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  color: black;
  padding: 12px;
  color: black;
  padding: 50px 0 0 0;
`

export const LockbookWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 7;
`

export const LockbookWrapperMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`
