import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const SubscriptionWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  height: 10vh;
  padding: 16px;
  z-index: 3000;
`
export const NewsletterConditions = styled.div`
  width: 400px;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  line-height: 22px;
`

export const NewsletterConditionsMobile = styled.div`
  width: 80%;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  line-height: 22px;
`

export const SubscribeInput = styled.input`
  boder: 1px solid black;
  width: 200px;
  border: none;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  height: 35px;
  margin: 0 20px 0 20px;
  &:focus {
    outline: none;
  }
  text-transform: capitalize;
  background-color: #f2f2f2;
`

export const SubscribeInputMobile = styled.input`
  width: 193px;
  font-family: 'LTUnivers-BasicRegular';
  border: none;
  font-size: 12px;
  height: 35px;
  margin: 20px 0px 20px -2px;
  &:focus {
    outline: none;
  }
  -webkit-appearance: none;
  text-transform: capitalize;
`

export const SubscribeButton = styled.button`
  boder: 1px solid black;
  width: 200px;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  height: 42px;
  background-color: white;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 14px 0 5px 0;
  &:hover {
    background: black;
    color: white;
  }
  -webkit-appearance: none;
`

export const SubscriptionWrapperMobile = styled.div`
  background-color: white;
  width: 100%;
  padding: 100px 16px 16px 16px;
`

export const SubscriptionLink = styled(Link)`
  background-color: white;
  color: black;
  width: 100%;
  font-size: 12px;
  padding: 16px 16px 16px 16px;
`

export const ResultContainer = styled.div`
  boder: 1px solid black;
  width: 400px;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 14px;
  height: 22px;
  background: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: black;
    color: white;
  }
`
