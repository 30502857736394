import React from 'react'
import BackgroundSlider from 'gatsby-image-background-slider'
import { Link, useStaticQuery } from 'gatsby'

import SEO from '~/components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />

    <Link to="/">
      <h1 style={{ color: 'white' }}>NOT FOUND, click to get back to Home</h1>
    </Link>
    <BackgroundSlider
      query={useStaticQuery(graphql`
        query {
          backgrounds: allFile(
            filter: { sourceInstanceName: { eq: "backgrounds" } }
          ) {
            nodes {
              relativePath
              childImageSharp {
                fluid(maxWidth: 5000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `)}
    />
  </>
)

export default NotFoundPage
